import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import News from "../views/News.vue";
import New from "../views/New.vue";
import Institution from "../views/Institution.vue";
import Gozsz from "../views/Gozsz.vue";
import Publicize from "../views/Publicize.vue";
import Service from "../views/Service.vue";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/publicize",
    name: "publicize",
    component: Publicize,
  },
  {
    path: "/service",
    name: "service",
    component: Service,
  },
  {
    path: "/news",
    name: "news",
    component: News,
  },
  {
    path: "/news/:id",
    name: "new",
    component: New,
  },
  {
    path: "/gozsz",
    name: "gozsz",
    component: Gozsz,
  },
  {
    path: "/Institution",
    name: "Institution",
    component: Institution,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash };
    }
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});

router.afterEach((to, from, next) => {
  NProgress.done();
  // next()
});


export default router;
