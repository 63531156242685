<template>
  <div class="container">
    <Navbar />
    <div
      :style="{
            backgroundImage:`url(${require('@/assets/newsbg.jpg')})`
        }"
      class="news-header"
    >
    </div>

    <div class="search">
      <el-popover
        :visible="visible"
        placement="bottom"
        title="检索结果"
        width="50%"
      >
        <template #reference>
          <el-input
            v-model="searchText"
            :prefix-icon="Search"
            class="w-50 m-2"
            placeholder="请输入新闻关键字"
            size="large"
            type="search"
            @blur="visible= false"
            @input="visible=true"
          />
        </template>

        <div v-if="searchnewslist.length">
          <div
            v-for="data in searchnewslist"
            :key="data._id"
            class="search-item"
            @click="handleChangepage(data._id)"
          >
            {{ data.title }}
          </div>
        </div>
        <div v-else>
          <el-empty
            :image-size="50"
            description="暂无新闻"
          />
        </div>
      </el-popover>
    </div>
    <div class="topnews">
      <el-row :gutter="20">
        <el-col
          v-for="item in topNewsList"
          :key="item._id"
          :span="6"
        >
          <el-card
            :body-style="{ padding: 0 }"
            shadow="hover"
            @click="handleChangepage(item._id)"
          >
            <div
              :style="{
                            backgroundImage:`url(${baseUrl}${item.cover})`
                        }"
              class="image"
            >
            </div>
            <div style="padding: 14px">
              <span>{{ item.title }}</span>
              <div class="bottom">
                <time class="time">{{ whichTime(item.editTime) }}</time>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-tabs
      v-model="whichTab"
      class="demo-tabs"
      style="margin:20px"
    >
      <el-tab-pane
        v-for="item in tablist"
        :key="item.name"
        :label="item.label"
        :name="item.name"
      >
        <el-row :gutter="20">
          <el-col :span="18">
            <div
              v-for="data in tabnews[item.name]"
              :key="data._id"
              style="padding:10px;"
            >
              <el-card
                :body-style="{ padding: 0 }"
                shadow="hover"
                @click="handleChangepage(data._id)"
              >
                <div
                  :style="{
                            backgroundImage:`url(${baseUrl}${data.cover})`
                        }"
                  class="tab-image"
                >
                </div>
                <div style="padding: 14px;float:left;">
                  <span>{{ data.title }}</span>
                  <div class="bottom">
                    <time class="tab-time">{{ whichTime(data.editTime) }}</time>
                  </div>
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :span="6">
            <el-timeline>
              <el-timeline-item
                v-for="(data, index) in tabnews[item.name]"
                :key="index"
                :timestamp="whichTime(data.editTime)"
              >
                {{ data.title }}
              </el-timeline-item>
            </el-timeline>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>

    <el-backtop :visibility-height="100" />
    <Footer />
  </div>
</template>

<script setup>
import { Search } from "@element-plus/icons-vue";
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import moment from "moment";
import axios from "axios";
import _ from "lodash";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

moment.locale("zh-cn");
const searchText = ref("");
const visible = ref(false);
const newlist = ref([]);

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

const whichTab = ref(1);
onMounted(async () => {
  const res = await axios.get(baseUrl + "/webapi/news/list");
  newlist.value = res.data.data;
});

const searchnewslist = computed(
  () =>
    searchText.value
      ? newlist.value.filter(item => item.title.includes(searchText.value))
      : [],
);

const topNewsList = computed(() => newlist.value.slice(0, 4));

const whichTime = time => {
  return moment(time).format("lll");
};

const tablist = [
  {
    label: "最新动态",
    name: 1,
  },
  {
    label: "纪检监察",
    name: 2,
  },
  {
    label: "通知公告",
    name: 3,
  },
];

const tabnews = computed(() => _.groupBy(newlist.value, item => item.category));

const router = useRouter();
const handleChangepage = (id) => {
  router.push(`/news/${id}`);
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
}

.news-header {
  width: 100%;
  height: 400px;
  background-size: cover;
}

.search {
  position: absolute;
  top: 300px;
  width: 100%;
  text-align: center;

  .el-input {
    width: 50%;
  }
}

.search-item {
  height: 50px;
  line-height: 50px;

  &:hover {
    background: whitesmoke;
    color: red;
  }
}

.topnews {
  margin: 20px;

  .image {
    width: 100%;
    height: 150px;
    background-size: cover;
  }

  .time {
    font-size: 13px;
    color: gray;
  }
}

.tab-image {
  width: 150px;
  height: 100px;
  background-size: cover;
  float: left;
}

.tab-time {
  font-size: 13px;
  color: gray;
}
</style>

