<!-- 顶部区域 -->
<template>
  <div class="navbar">
    <el-menu
      :default-active="route.fullPath"
      :router="true"
      class="el-menu-demo"
      mode="horizontal"
    >
      <el-menu-item index="/">首页</el-menu-item>
      <el-menu-item index="/publicize">政务公开</el-menu-item>
      <el-menu-item index="/service">办事服务</el-menu-item>
      <el-menu-item index="/news">新闻中心</el-menu-item>
      <el-menu-item index="/gozsz">走进圳上</el-menu-item>
      <el-menu-item class="right-align" index="/#about-anchor">关于我们</el-menu-item>
    </el-menu>
  </div>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();

</script>

<style lang="scss" scoped>
.navbar {
  position: sticky;
  width: 100%;
  background: #2661ab;

  .el-menu-demo {
    width: 1200px;
    background-color: transparent;
    margin: 0 auto;

    .el-menu-item {
      font-size: 22px;
      color: white;
    }
  }
}

.right-align {
  position: absolute; /* 设置绝对定位 */
  right: 0; /* 移动到右侧 */
}

</style>