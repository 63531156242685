<template>
  <div class="about-us">
    <h2>关于我们</h2>
    <h3>{{ systemInfo.unit }}</h3>
    <div class="organization-info">
      <h3>机构简介</h3>
      <p>{{ systemInfo.description }}</p>
    </div>
    <div class="contact-info">
      <h3>联系方式</h3>
      <ul>
        <li>地址: {{ systemInfo.address }}</li>
        <li>电话: {{ systemInfo.phone }}</li>
        <li>邮箱: {{ systemInfo.email }}</li>
      </ul>
    </div>

    <div class="feedback-form">
      <h3>投诉建议</h3>
      <p>如果您有任何意见或建议，请通过以上方式联系我们：</p>
      <form @submit.prevent="handleSubmit">
        <label for="feedback">反馈内容:</label>
        <textarea id="feedback" v-model="feedback" required></textarea>
        <button type="submit">提交</button>
      </form>
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { ref, onMounted } from "vue";

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

// 定义响应式数据
const systemInfo = ref({});

onMounted(async () => {
  try {
    const response = await axios.get(baseUrl + "/webapi/systemInfo");
    systemInfo.value = response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
});

const feedback = ref("");

// 处理提交逻辑
const handleSubmit = () => {
  console.log("Feedback submitted:", feedback.value);
  feedback.value = ""; // 清空输入框
};
</script>

<style lang="scss" scoped>
.about-us {
  font-family: Arial, sans-serif;
  width: 1200px;
  margin: 0 auto 30px auto;

  h2, h3 {
    margin-bottom: 10px;
  }

  p, li {
    margin-bottom: 5px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  textarea {
    resize: vertical;
    min-height: 100px;
  }
}
</style>
