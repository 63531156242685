<!--
 * @作者: kerwin
-->
<template>
  <div>
    <el-carousel
      v-if="looplist.length"
      :autoplay="false"
      direction="vertical"
      height="calc(100vh - 59px)"
    >
      <el-carousel-item
        v-for="item in looplist"
        :key="item._id"
      >
        <div
          :style="{backgroundImage:`url(process.env.VUE_APP_API_BASE_URL${item.cover})`}"
          class="item"
        >
          <el-card class="box-card">
            <template #header>
              <div class="card-header">
                <h2>{{ item.title }}</h2>
              </div>
            </template>
            <div>{{ item.introduction }}</div>
            <div class="detail">{{ item.detail }}</div>

            <div class="more">
              更多信息，请访问：
              <br>
              http://kerwin:8080
            </div>
          </el-card>
        </div>
      </el-carousel-item>
    </el-carousel>
    <el-empty
      v-else
      description="暂无产品"
    />

  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import axios from "axios";

const looplist = ref([]);

onMounted(async () => {
  const res = await axios.get("/webapi/institution/list");
  console.log(res);
  looplist.value = res.data.data;
});
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  height: 100%;
  background-image: cover;
}

.box-card {
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);

  .detail, .more {
    margin-top: 20px;
  }
}
</style>
