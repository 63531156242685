<!-- 首页 -->
<template>
  <!-- 头部区域-->
  <header class="header">
    <!-- 头-->
    <nav class="header_top">
      <ul>
        <li class="time_show">日期</li>
        <li class="weather_show">天气</li>
        <li class="login" @click="handleClick">登录</li>
      </ul>
    </nav>

    <!-- 搜索框-->
    <form class="search-form" @submit.prevent="handleSearch">
      <input v-model="searchQuery" class="search-input" placeholder="请输入关键词查询" required type="text">
      <button class="search-button" type="submit">搜索</button>
    </form>

    <!-- 中间-->
    <div class="header_center">
      <div class="logo-container">
        <img alt="" class="center_logo" src="../assets/zszis.large.logo.png">
      </div>
    </div>

    <Navbar />
  </header>

  <!-- 主体区域-->
  <main class="main">
    <div class="main-container">
      <!-- 轮播图区域 -->
      <div class="carousel">
        <Carousel :height="carouselHeight" :images="images" />
      </div>

      <div class="slide-tab">

      </div>
    </div>

    <!-- 热点专题-->
    <section class="hot-topics">
      <h2>热点专题</h2>
      <div class="topics-grid">
        <div v-for="(topic, index) in topics" :key="index" class="topic-item">
          <a :href="topic.link">
            <img :alt="topic.title" :src="topic.image" />
            <p>{{ topic.title }}</p>
          </a>
        </div>
      </div>
    </section>
  </main>

  <div class="center">国务院、省、市、县链接</div>

  <!-- 关于我们 -->
  <AboutUs id="about-anchor" />

  <!-- 版权信息 -->
  <Footer />

</template>

<script setup>
import { onMounted, ref } from "vue";
import Navbar from "@/components/Navbar.vue";
import Carousel from "@/components/Carousel.vue";
import Footer from "@/components/Footer.vue";
import AboutUs from "@/components/AboutUs.vue";

// 图片数据
const images = [
  { id: 1, src: `${require("@/assets/home1.png")}`, caption: "图片说明1" },
  { id: 2, src: `${require("@/assets/home2.png")}`, caption: "图片说明2" },
  { id: 3, src: `${require("@/assets/home3.png")}`, caption: "图片说明3" },
];

const carouselHeight = "372px";
const handleClick = () => {
  window.location = "http://localhost:8080";
};

// 定义搜索查询字符串
const searchQuery = ref("");

// 处理搜索表单提交事件
const handleSearch = () => {
  console.log("搜索关键词:", searchQuery.value);
  // 这里可以添加更多的逻辑，例如调用 API 进行搜索
};

// 定义热点专题数据
const topics = ref([
  {
    title: "专题一",
    link: "#topic1",
    image: `${require("@/assets/topic1.png")}`,
  },
  {
    title: "专题二",
    link: "#topic2",
    image: `${require("@/assets/topic2.png")}`,
  },
  {
    title: "专题三",
    link: "#topic3",
    image: `${require("@/assets/topic3.png")}`,
  },
  {
    title: "专题四",
    link: "#topic4",
    image: `${require("@/assets/topic4.png")}`,
  },
]);

</script>

<style lang="scss" scoped>

.header {
  width: 100%;
  background: #e6e6e6;

  .header_top {
    width: 1200px;
    height: 59px;
    margin: 0 auto;

    .login {
      margin-left: auto; /* 将login推到右侧 */
    }

    ul {
      display: flex;
      list-style-type: none;

      li {
        line-height: 59px;
        margin: 0 20px;
        cursor: pointer;
      }
    }
  }

  .search-form {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 40px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 999; /* 确保 search-form 位于其他元素之上 */

    .search-input {
      width: 80%;
      height: 100%;
      padding: 0 15px;
      border: none;
      outline: none;
      font-size: 16px;
      background-color: transparent;
    }

    .search-button {
      width: 20%;
      height: 100%;
      background-color: #007bff;
      color: #fff;
      border: none;
      outline: none;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .header_center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 350px;
    background-size: cover;
    background: url('@/assets/home2.png') no-repeat center;

    .logo-container {
      margin-bottom: 10px; /* 与搜索框之间的间距 */
      .center_logo {
        height: 204px;
        margin: 0 auto;
      }
    }
  }
}

.main {
  width: 100%;

  .main-container {
    display: flex;
    align-items: center;
    justify-content: space-between; /* 左右对齐 */
    width: 1200px;
    height: 452px;
    margin: 0 auto;
    padding: 30px 0;

    .carousel {
      width: 52%;
      height: 100%;
    }

    .slide-tab {
      width: 46%;
      height: 100%;
      background: #007bff;
    }
  }
}

.center {
  width: 1200px;
  height: 300px;
  margin: 0 auto 30px;
  background: #00B7FF;
}

.hot-topics {
  width: 1200px;
  margin: 0 auto 30px;

  h2 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #8c1a1a;
  }

  .topics-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); // 两列布局
    gap: 20px;

    .topic-item {
      text-align: center;
      border: 1px solid #ccc; /* 添加边框 */

      a {
        display: inline-block;
        width: 100%; // 确保链接块占满宽度

        img {
          width: 100%;
          height: 180px;
        }

        p {
          height: 40px;
          line-height: 40px;
          font-size: 14px;
        }
      }
    }
  }
}

</style>