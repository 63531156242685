<template>
  <el-carousel height=height indicator-position="outside">
    <el-carousel-item v-for="item in items" :key="item.id">
      <img :src="item.src" alt="Carousel Image" class="carousel-image">

      <div class="carousel-caption" style="text-align: left; bottom: 0; left: 0;">
        {{ item.caption }}
      </div>
    </el-carousel-item>
  </el-carousel>
</template>
<script setup>
import { ref, defineProps } from "vue";
import { ElCarousel, ElCarouselItem } from "element-plus";

// 定义 props
const props = defineProps({
  images: {
    type: Array,
    required: true,
    default: () => [],
  },
});
// 图片数据
const items = ref(props.images);
</script>

<style lang="scss" scoped>
.carousel-image {
  width: 100%;
  height: 100%;
}

.carousel-caption {
  position: absolute;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
}
</style>
