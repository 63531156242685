<template>
  <!-- 底部区域-->
  <footer class="footer">
    <a href="https://beian.miit.gov.cn" target="_blank">湘ICP备2024081509号-1&nbsp;&nbsp;&nbsp;&nbsp;版权所有 ©
      2022-2024&nbsp;&nbsp;&nbsp;&nbsp;e代农夫 保留所有权利。</a>
  </footer>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 40px;
  border-top: 3px solid #2661ab;
  background: #e6e6e6;

  a {
    display: block;
    width: 1200px;
    height: 40px;
    margin: 0 auto;
    line-height: 40px;
    text-align: center;
    text-decoration: none;
    color: #666666;
  }
}
</style>