<template>
  <div class="container">
  <navbar />
  <el-row>
    <el-col
      :offset="1"
      :span="17"
    >
      <div>
        <h2> {{ currentNews.title }}</h2>

        <div class="time">
          {{ whichTime(currentNews.editTime) }}
        </div>

        <el-divider>
          <el-icon>
            <star-filled />
          </el-icon>
        </el-divider>

        <div v-html="currentNews.content"></div>
      </div>
    </el-col>
    <el-col
      :offset="1"
      :pull="1"
      :span="4"
    >
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span style="font-size:16px;font-weight:bold">最近新闻</span>

          </div>
        </template>
        <div
          v-for="item in topNews"
          :key="item._id"
          class="text item"
          style="padding:14px"
          @click="handleChange(item._id)"
        >
          <span>{{ item.title }}</span>
          <div class="bottom">
            <time class="time">{{ whichTime(item.editTime) }}</time>

          </div>
        </div>
      </el-card>
    </el-col>
  </el-row>
  <Footer />
    </div>
</template>

<script setup>
import { onMounted, ref, watchEffect, onBeforeUnmount } from "vue";
import axios from "axios";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import { StarFilled } from "@element-plus/icons-vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

moment.locale("zh-cn");

const route = useRoute();
const router = useRouter();
const currentNews = ref({});
const topNews = ref([]);

// 获取环境变量
const baseUrl = process.env.VUE_APP_API_BASE_URL;

const stop = watchEffect(async () => {
  if (!route.params.id) return;
  const res1 = await axios.get(`${baseUrl}/webapi/news/list/${route.params.id}`);
  const res2 = await axios.get(`${baseUrl}/webapi/news/toplist?limit=4`);
  currentNews.value = res1.data.data[0];
  topNews.value = res2.data.data;
});

onBeforeUnmount(() => {
  stop();
});

const whichTime = time => {
  return moment(time).format("lll");
};

const handleChange = (id) => {
  router.push(`/news/${id}`);
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-top: 30px;
}

.time {
  font-size: 13px;
  color: gray;
}
</style>
